import React from 'react';

import Layout from '../../../components/layout';
import Container from '../../../components/container';
import ResidenceDetails from '../../../components/residence-details';

import Real1 from '../../../images/work/02_PB_MID_COAST/PHOTOS/MI_01.jpg';
import Real2 from '../../../images/work/02_PB_MID_COAST/PHOTOS/MI_02.jpg';
import Real3 from '../../../images/work/02_PB_MID_COAST/PHOTOS/MI_03.jpg';
import Real4 from '../../../images/work/02_PB_MID_COAST/PHOTOS/MI_04.jpg';
import Real5 from '../../../images/work/02_PB_MID_COAST/PHOTOS/MI_05.jpg';
import Real6 from '../../../images/work/02_PB_MID_COAST/PHOTOS/MI_06.jpg';
import Real7 from '../../../images/work/02_PB_MID_COAST/PHOTOS/MI_07.jpg';
import Real1s from '../../../images/work/02_PB_MID_COAST/PHOTOS/450x280/MI-SM_01.jpg';
import Real2s from '../../../images/work/02_PB_MID_COAST/PHOTOS/450x280/MI-SM_02.jpg';
import Real3s from '../../../images/work/02_PB_MID_COAST/PHOTOS/450x280/MI-SM_03.jpg';
import Real4s from '../../../images/work/02_PB_MID_COAST/PHOTOS/450x280/MI-SM_04.jpg';
import Real5s from '../../../images/work/02_PB_MID_COAST/PHOTOS/450x280/MI-SM_05.jpg';
import Real6s from '../../../images/work/02_PB_MID_COAST/PHOTOS/450x280/MI-SM_06.jpg';
import Real7s from '../../../images/work/02_PB_MID_COAST/PHOTOS/450x280/MI-SM_07.jpg';
// import Process1 from '../../../images/work/02_PB_MID_COAST/PROCESS/MID_01.jpg'
// import Process2 from '../../../images/work/02_PB_MID_COAST/PROCESS/MID_02.jpg'
// import Process3 from '../../../images/work/02_PB_MID_COAST/PROCESS/MID_03.jpg'
// import Process4 from '../../../images/work/02_PB_MID_COAST/PROCESS/MID_04.jpg'
// import Process5 from '../../../images/work/02_PB_MID_COAST/PROCESS/MID_05.jpg'
// import Process6 from '../../../images/work/02_PB_MID_COAST/PROCESS/MID_06.jpg'
// import Process7 from '../../../images/work/02_PB_MID_COAST/PROCESS/MID_07.jpg'
// import Process8 from '../../../images/work/02_PB_MID_COAST/PROCESS/MID_08.jpg'
// import Process9 from '../../../images/work/02_PB_MID_COAST/PROCESS/MID_09.jpg'
// import Process10 from '../../../images/work/02_PB_MID_COAST/PROCESS/MID_10.jpg'
// import Process1s from '../../../images/work/02_PB_MID_COAST/PROCESS/450x280/MID-SM_01.jpg'
// import Process2s from '../../../images/work/02_PB_MID_COAST/PROCESS/450x280/MID-SM_02.jpg'
// import Process3s from '../../../images/work/02_PB_MID_COAST/PROCESS/450x280/MID-SM_03.jpg'
// import Process4s from '../../../images/work/02_PB_MID_COAST/PROCESS/450x280/MID-SM_04.jpg'
// import Process5s from '../../../images/work/02_PB_MID_COAST/PROCESS/450x280/MID-SM_05.jpg'
// import Process6s from '../../../images/work/02_PB_MID_COAST/PROCESS/450x280/MID-SM_06.jpg'
// import Process7s from '../../../images/work/02_PB_MID_COAST/PROCESS/450x280/MID-SM_07.jpg'
// import Process8s from '../../../images/work/02_PB_MID_COAST/PROCESS/450x280/MID-SM_08.jpg'
// import Process9s from '../../../images/work/02_PB_MID_COAST/PROCESS/450x280/MID-SM_09.jpg'
// import Process10s from '../../../images/work/02_PB_MID_COAST/PROCESS/450x280/MID-SM_10.jpg'

const Residence02 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="MEDITERRANEAN REVIVAL"
        residenceLocation="PEBBLE BEACH, CALIFORNIA"
        infoList={[
          'New 8,500 SF two-story single family residence with attached 690 SF caretaker unit. 2,230 SF stone terrace with decorative pool, spa, and fire pit.',
          'Design - 2012',
          'Construction - 2013-2016',
          'Architect of record: International Design Group, Inc',
          'General Contractor: Coast Line Construction, Inc',
          'Structural Engineer: BCA Structural Engineering, Inc',
        ]}
        testimonialList={[
          'I have had the honor of working with Anatoly Ostretsov for five years, as we built our dream home. Thanks to Anatoly and my contractor, John Cogbill of Coastline Construction, our home is magnificent. We originally hired John Matthams to design our home. When John became ill, he turned our job over to Anatoly.',
          'Anatoly stepped right into a difficult situation, and designed and created our beautiful home. He is a wonderful listener and designed exactly what our vision was for our home. He truly is gifted as an architect. For every issue we ran into, Anatoly created an amazingly functional and physically beautiful solution.',
          'We wanted to make our home in Pebble Beach unique. It is filled with architectural wonder and amazing artistry. Anatoly was able to work well with a stained glass artist from Montana, a stone cutter from France, and various other subs that were brought in to create our vision.',
          'Anatoly is extremely knowledgeable, diligent at his craft, reliable, and a pleasure to work with. He also understands the county’s ordinances and building code requirements and works extremely well with them to resolve any issues that may arise. I recommend him, without reservation. He is the only architect in Monterey County that I would use. I am sure that he won’t disappoint you.',
          'Amy',
        ]}
        photos={[Real1, Real2, Real3, Real4, Real5, Real6, Real7]}
        photosSmall={[Real1s, Real2s, Real3s, Real4s, Real5s, Real6s, Real7s]}
        processPhotos={[]}
        processPhotosSmall={[]}
      />
    </Container>
  </Layout>
);

export default Residence02;
